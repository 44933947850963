import React from "react"
import Layout from "../components/layout"
import ElementorRenderer from "../components/elementorrenderer"
import SEO from "../components/seo"
import "../styles/mainStyles.scss"
//import { element } from "prop-types"

const pageTemplate = data => {
  const elementorData = data.pageContext.modifiedData
  //console.log("data=", data);
  const blocks = data.pageContext.blocks
  const headerData = blocks.find(block => block.title === "Main Header")
  const footerData = blocks.find(block => block.title === "Footer NC")
  return (
    <Layout
      headerData={headerData}
      footerData={footerData}
      mainClass={data.pageContext.slug}
    >
      <SEO
        title={data.pageContext.seoTitle}
        description={data.pageContext.seoDescription}
      />
      {ElementorRenderer(elementorData)}
    </Layout>
  )
}
export default pageTemplate
