import React from "react"


const GoogleMap = ({ settings, id }) => {
  console.log("settings map=", settings)
  function createMarkup() {
    return { __html: settings["Lien Iframe"] }
  }

  return <div dangerouslySetInnerHTML={createMarkup()} />
}
export default GoogleMap
