import React from "react"

const Heading = ({ settings, id }) => {
  //console.log("heading setting=", settings)

  //return <{settings.header_size}>{settings.title}</{settings.header_size}>
  return React.createElement(
    "div",
    { key: id, id: settings.anchor ? settings.anchor : null },
    React.createElement(settings.header_size || "h2", {
      dangerouslySetInnerHTML: { __html: settings.title },
    })
  )
}

export default Heading
