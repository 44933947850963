import React from "react"
import ElementorRenderer from "../elementorrenderer"

const Section = ({ id, elements, settings: { structure } }) => {
  //console.log("enfants=", elements)
  const structureType = structure ? structure : "100"
  const eltypes = {}
  elements.forEach(item => {
    item.elements.forEach(element => {
      eltypes[
        element.widgetType === "partner-cards" ||
        element.widgetType === "startup-cards"
          ? element.settings.style + " " + element.widgetType
          : element.widgetType
      ] = true
    })
  })
  //console.log("eltypes,", eltypes)
  const subElements = Object.keys(eltypes).join("-widget ")
  //console.log("subElements=", subElements)
  return (
    <section
      key={id}
      id={id}
      className={
        "section section-" + structureType + " " + subElements + "-widget"
      }
    >
      <div className="container">
        <div className="columns">{ElementorRenderer(elements)}</div>
      </div>
    </section>
  )
}

export default Section
