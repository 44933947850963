import React from "react"
import YouTube from "react-youtube"

const YTPlayer = ({ settings, id }) => {
  //console.log("YTSetting=", settings)
  return (
    <YouTube
      key={id}
      videoId={settings.video_id}
      containerClassName={"yt-player"}
    />
  )
}

export default YTPlayer
