import React from "react"

const TextEditor = ({ settings, id }) => {
  //console.log("text=", settings)
  return (
    <div
      key={id}
      className="richtext"
      dangerouslySetInnerHTML={{ __html: settings.text_description }}
    />
  )
}

export default TextEditor
