import React from "react"
import Img from "gatsby-image"

const StartupCards = ({ settings, id }) => {
  //console.log(settings)
  return (
    <div
      key={id}
      className={
        "startup-cards is-" +
        settings.style +
        " columns is-variable is-5 is-multiline"
      }
    >
      {settings.startup_cards.map((item, idx) => (
        <div
          key={idx}
          className="startup-card column is-one-third-desktop is-half-tablet"
        >
          <div className="card">
            <div className="card-image">
              <figure className="image">
                <Img fluid={item.image.fluid} />
              </figure>
            </div>
            <div className="card-content">
              <div
                className="content description"
                dangerouslySetInnerHTML={{ __html: item.description }}
              />
              <div className="startup-link">
                <a href={item.link.url}>{item.link.url}</a>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default StartupCards
