import React from "react"

import Section from "./elements/Section"
import Column from "./elements/Column"

import AccordionItems from "./widgets/Accordion"
import CarouselItems from "./widgets/Carousel"
import CTA from "./widgets/CTA"
import Heading from "./widgets/Heading"
import IconTextListing from "./widgets/IconTextListing"
import Image from "./widgets/Image"
import ImageGallery from "./widgets/ImageGallery"
import ImageTextCTA from "./widgets/ImageTextCTA"
import KeyNumber from "./widgets/KeyNumber"
import LinkListing from "./widgets/LinkListing"
import Listing from "./widgets/Listing"
import NavMenu from "./widgets/NavMenu"
import Newsletter from "./widgets/Newsletter"
import PartnerCards from "./widgets/PartnerCards"
import SocialIcons from "./widgets/SocialIcon"
import StartupCards from "./widgets/StartupCards"
import TextCTA from "./widgets/TextCTA"
import TextEditor from "./widgets/TextEditor"
import YTPlayer from "./widgets/YoutubePlayer"
import GoogleMap from "./widgets/GoogleMap"

const elements = {
  section: Section,
  column: Column,
}

const widgets = {
  afaire: ({ settings, id, widgetType }) => (
    <pre key={id}>
      name={widgetType}
      <br />
      settings={JSON.stringify(settings, null, 2)}
    </pre>
  ),
  "nc-image": Image,
  "nc-heading": Heading,
  text: TextEditor,
  keynumber: KeyNumber,
  "text-and-cta": TextCTA,
  "image-text-and-cta": ImageTextCTA,
  "nc-cta": CTA,
  "nc-nav": NavMenu,
  "nc-carousel": CarouselItems,
  "nc-list": Listing,
  "nc-accordion": AccordionItems,
  "nc-icon-text": IconTextListing,
  "nc-link-list": LinkListing,
  newsletter: Newsletter,
  "nc-social-icon": SocialIcons,
  "startup-cards": StartupCards,
  "partner-cards": PartnerCards,
  "nc-gallery": ImageGallery,
  "nc-youtube-player": YTPlayer,
  "nc-google-map": GoogleMap,
}

//function ucwords(text) {
//let str = text.toLowerCase()
//return str.replace(/(^([a-zA-Z\p{M}]))|([ -][a-zA-Z\p{M}])/g, function(s) {
//return s.toUpperCase()
//})
//}

// ? Image({ settings: elt.settings })
//<Image url={elt.settings} />

const ElementorRenderer = data => {
  return data.map(elt => {
    //console.log("elt=", elt)
    switch (elt.elType) {
      case "section":
      case "column":
        return elements[elt.elType](elt)
      case "widget":
        return elt.widgetType && widgets[elt.widgetType]
          ? widgets[elt.widgetType](elt)
          : widgets["afaire"](elt)
      default:
        return (
          <div key={elt.id}>
            <div>Non reconnu </div>
            <pre>debug={JSON.stringify(elt, null, 2)}</pre>
          </div>
        )
    }
  })
}

export default ElementorRenderer
