import React from "react"

const IconTextListing = ({ settings, id }) => {
  //console.log("icon text=", settings)
  return (
    <ul className="icon-text-listing" key={id}>
      {settings.icon_list.map((item, idx) => (
        <li key={idx} className={item.orientation}>
          <i className={item.selected_icon.value + " fa-lg"}></i>
          <div dangerouslySetInnerHTML={{ __html: item.text }} />
        </li>
      ))}
    </ul>
  )
}

export default IconTextListing
