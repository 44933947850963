import React from "react"
import ElementorRenderer from "./elementorrenderer"

const Header = ({ headerData }) => {
  return (
    <header>
      {headerData ? ElementorRenderer(headerData.elementorData) : ""}
    </header>
  )
}

export default Header
