import React from "react"

const LinkListing = ({ settings, id }) => {
  //console.log(settings)
  return (
    <ul className="link-listing" key={id}>
      {settings.list.map((item, idx) => (
        <li key={idx}>
          <a
            href={item.link.url}
            target={item.link.is_external ? "_blank" : "_self"}
          >
            {item.list_entry}
          </a>
        </li>
      ))}
    </ul>
  )
}

export default LinkListing
