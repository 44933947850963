import React from "react"
import { StaticQuery, graphql } from "gatsby"
import NavBar from "./NavBar"

export default function navMenu({ settings, id }) {
  return (
    <StaticQuery
      key={id}
      query={graphql`
        query allMenus {
          wordPress {
            menus {
              edges {
                menuData: node {
                  id
                  name
                  slug
                  menuItems {
                    edges {
                      node {
                        label
                        url
                        childItems {
                          edges {
                            node {
                              url
                              label
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={({
        wordPress: {
          menus: { edges },
        },
      }) => {
        const {
          menuData: { menuItems },
        } = edges.find(v => v.menuData.slug === settings.menu)
        //console.log("menuItems.edges=", menuItems.edges)
        return <NavBar menuItems={menuItems} />
      }}
    />
  )
}
