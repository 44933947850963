import React, { useState } from "react"
import CountUp from "react-countup"
import VisibilitySensor from "react-visibility-sensor"

const KeyNumber = ({ settings, id }) => {
  const [viewPortEntered, setViewPortEntered] = useState(false)

  console.log("settings keynumber=", settings)
  return (
    <div key={id} className="number-with-text">
      <div>
        <CountUp
          start={viewPortEntered ? null : 0}
          end={parseInt(settings.number)}
          duration={3}
        >
          {({ countUpRef }) => {
            return (
              <VisibilitySensor
                active={!viewPortEntered}
                onChange={isVisible => {
                  if (isVisible) {
                    setViewPortEntered(true)
                  }
                }}
                delayedCall
              >
                <p ref={countUpRef} />
              </VisibilitySensor>
            )
          }}
        </CountUp>
      </div>

      <div className="number-desc">{settings.label_heading}</div>
    </div>
  )
}

export default KeyNumber
