import React from "react"
import "@fortawesome/fontawesome-free/css/all.css"

const SocialIcons = ({ settings, id }) => {
  //console.log("icon text=", settings)
  return (
    <div key={id}>
      {settings.social_icon_list.map((item, idx) => (
        <a key={idx} href={item.link.url} target="_blank" rel="noreferrer">
          <span className="icon is-medium">
            <i className={item.social_icon.value + " fa-2x"}></i>
          </span>
        </a>
      ))}
    </div>
  )
}

export default SocialIcons
