import React from "react"
import Img from "gatsby-image"

const PartnerCards = ({ settings, id }) => {
  //console.log(settings)
  return (
    <div key={id} className={"partner-cards is-" + settings.style}>
      <h2>{settings.title}</h2>
      {settings.partner_cards.map((item, idx) => (
        <div key={idx} className="columns">
          <div className={"partner-card column"}>
            <div className="media">
              {settings.style === "reg" ? (
                <div className="media-left">
                  <figure className="image">
                    <Img fluid={item.image.fluid} />
                  </figure>
                </div>
              ) : (
                ""
              )}
              <div className="media-content">
                <div
                  className="description"
                  dangerouslySetInnerHTML={{ __html: item.description }}
                />
                <div className="partner-link">
                  <a href={item.link.url}>{item.link.url}</a>
                </div>
              </div>
              {settings.style === "fond" ? (
                <div className="media-right">
                  <figure className="image">
                    <Img fluid={item.image.fluid} />
                  </figure>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default PartnerCards
