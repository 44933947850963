import React, { useState } from "react";
import gql from "graphql-tag";
import { Mutation } from "react-apollo";
import Popup from "reactjs-popup";

const CONTACT_FORM_MUTATION = gql`
  mutation CreateSubmissionMutation(
    $clientMutationId: String!
    $name: String!
    $email: String!
    $message: String!
    $phone: String!
  ) {
    createSubmission(
      input: {
        clientMutationId: $clientMutationId
        name: $name
        email: $email
        message: $message
        phone: $phone
      }
    ) {
      success
      data
    }
  }
`;

const Contact = () => {
  const [nameValue, setNameValue] = useState("");
  const [emailValue, setEmailValue] = useState("");
  const [messageValue, setMessageValue] = useState("");
  const [phoneValue, setPhoneValue] = useState("");

  const [formState, setFormState] = useState(""); // Possible values: "", "loading", "success"
  const [isError, setIsError] = useState(false); // Pour gérer l'affichage des erreurs

  return (
    <Popup
      trigger={
        <button className="contact-btn">
          <b>Contactez nous !</b>
          <br />
          Devis gratuit
        </button>
      }
      modal
      closeOnDocumentClick
      contentStyle={{
        width: "auto",
        background: "transparent",
        border: "0",
      }}
    >
      {(close) => (
        <React.Fragment>
          <div className="modal-content">
            <Mutation mutation={CONTACT_FORM_MUTATION}>
              {(createSubmission, { loading, error, data }) => {
                // Déterminer la classe à appliquer en fonction de l'état
                if (loading) {
                  setFormState("loading");
                  setIsError(false);
                } else if (data && data.createSubmission.success) {
                  setFormState("success");
                  setIsError(false);
                } else if (error) {
                  setFormState(""); // Réinitialise la classe en cas d'erreur
                  setIsError(true);
                }

                return (
                  <React.Fragment>
                    <div className={`newsletter ${formState}`}>
                      {formState === "loading" && (
                        <div className="loader"></div> // Affichage du loader pendant le chargement
                      )}
                      {formState === "success" && (
                        <p className="help is-success">
                          Merci pour votre message, nous reviendrons vers vous au plus vite !
                        </p>
                      )}
                      {formState === "" && (
                        <form
                          onSubmit={async (event) => {
                            event.preventDefault();
                            setIsError(false); // Réinitialise l'état d'erreur avant la soumission
                            setFormState("loading"); // Change l'état au début du chargement
                            try {
                              await createSubmission({
                                variables: {
                                  clientMutationId: emailValue,
                                  name: nameValue,
                                  email: emailValue,
                                  message: messageValue,
                                  phone: phoneValue,
                                },
                              });
                            } catch (e) {
                              setIsError(true); // Gérer l'erreur ici
                            }
                          }}
                        >
                          <h2>Remplir le formulaire</h2>
                          <div className="field">
                            <div className="control has-icons-left">
                              <input
                                className="input"
                                type="text"
                                placeholder="Entrez votre nom"
                                id="nameInput"
                                value={nameValue}
                                onChange={(event) => {
                                  setNameValue(event.target.value);
                                }}
                                required
                              />
                              <span className="icon is-small is-left">
                                <i className="far fa-user"></i>
                              </span>
                            </div>
                          </div>
                          <div className="field">
                            <div className="control has-icons-left">
                              <input
                                className="input"
                                type="phone"
                                placeholder="Entrez votre numéro de téléphone"
                                id="phoneInput"
                                value={phoneValue}
                                onChange={(event) => {
                                  setPhoneValue(event.target.value);
                                }}
                                required
                              />
                              <span className="icon is-small is-left">
                                <i className="fas fa-phone-alt"></i>
                              </span>
                            </div>
                          </div>
                          <div className="field">
                            <div className="control has-icons-left">
                              <input
                                className="input"
                                type="email"
                                placeholder="Entrez votre email"
                                id="emailInput"
                                value={emailValue}
                                onChange={(event) => {
                                  setEmailValue(event.target.value);
                                }}
                                required
                              />
                              <span className="icon is-small is-left">
                                <i className="fas fa-envelope"></i>
                              </span>
                            </div>
                          </div>
                          <div className="field">
                            <div className="control">
                              <textarea
                                className="textarea"
                                placeholder="Entrez votre message"
                                id="messageInput"
                                value={messageValue}
                                onChange={(event) => {
                                  setMessageValue(event.target.value);
                                }}
                                required
                              />
                            </div>
                          </div>
                          <div className="field">
                            <div className="control has-text-centered">
                              <button type="submit" className="button is-primary">
                                Envoyer
                              </button>
                            </div>
                          </div>
                          {isError && (
                            <p className="help is-danger">
                              Une erreur est survenue, veuillez réessayer et nous excuser pour la gêne occasionnée.
                            </p>
                          )}
                        </form>
                      )}
                       <button
                          className="modal-close is-large"
                          aria-label="close"
                          onClick={() => {
                            close();
                            setFormState(""); // Réinitialise l'état lors de la fermeture de la popup
                            setIsError(false); // Réinitialise l'état d'erreur lors de la fermeture de la popup
                          }}
                        ></button>
                    </div>
                  </React.Fragment>
                );
              }}
            </Mutation>
          </div>
        </React.Fragment>
      )}
    </Popup>
  );
};

export default Contact;
