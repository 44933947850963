import React from "react"
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion"
import "react-accessible-accordion/dist/fancy-example.css"
import Img from "gatsby-image"

const AccordionItems = ({ settings, id }) => {
  //console.log("{settings.accordions=", settings.accordions)
  return (
    <Accordion key={id} allowZeroExpanded={true}>
      {settings.accordions.map((item) => (
        <AccordionItem key={item._id}>
          <AccordionItemHeading>
            <AccordionItemButton>{item.accordion_title}</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            {item.accordion_image ? <Img fluid={item.accordion_image.fluid} alt={item.accordion_image.altText ? item.accordion_image.altText : null} /> : null}
            <div dangerouslySetInnerHTML={{ __html: item.accordion_content }} />
          </AccordionItemPanel>
        </AccordionItem>
      ))}
    </Accordion>
  )
}

export default AccordionItems
