import React from "react"
import { Link } from "gatsby"
import NavbarBurger from "./NavBarBurger"
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion"
import "react-accessible-accordion/dist/fancy-example.css"

export default class Navbar extends React.Component {
  state = {
    activeMenu: false,
  }
  toggleMenu = () => {
    this.setState({
      activeMenu: !this.state.activeMenu,
    })
  }

  render() {
    const menuItems = this.props.menuItems
    return (
      <nav className="navbar" role="navigation" aria-label="main navigation">
        <div className="navbar-brand">
          <NavbarBurger
            active={this.state.activeMenu}
            toggleMenu={this.toggleMenu}
          />
        </div>

        <div className={"navbar-menu"}>
          {menuItems.edges.map((menuItem, idx) =>
            menuItem.node.childItems.edges.length ? (
              <div key={idx} className="navbar-item has-dropdown is-hoverable">
                <Link className="navbar-item" to={menuItem.node.url}>
                  {menuItem.node.label}
                </Link>
                <div className="navbar-dropdown">
                  {menuItem.node.childItems.edges.map((subItem, idx) => (
                    <Link
                      key={idx}
                      className="navbar-item"
                      to={subItem.node.url}
                    >
                      {subItem.node.label}
                    </Link>
                  ))}
                </div>
              </div>
            ) : (
              <Link key={idx} className="navbar-item" to={menuItem.node.url}>
                {menuItem.node.label}
              </Link>
            )
          )}
        </div>
        <div
          className={
            "is-mobile navbar-menu" +
            (this.state.activeMenu ? " is-active" : "")
          }
        >
          {menuItems.edges.map((menuItem, idx) =>
            menuItem.node.childItems.edges.length ? (
              <Accordion key={idx} allowZeroExpanded={true}>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      {menuItem.node.label}
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <div className="sub-menu-items">
                      {menuItem.node.childItems.edges.map((subItem, idx) => (
                        <Link
                          key={idx}
                          className="navbar-item"
                          to={subItem.node.url}
                        >
                          {subItem.node.label}
                        </Link>
                      ))}
                    </div>
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
            ) : (
              <Link key={idx} className="navbar-item" to={menuItem.node.url}>
                {menuItem.node.label}
              </Link>
            )
          )}
        </div>
      </nav>
    )
  }
}
