import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

const ImageTextCTA = ({ settings, id }) => {
  //console.log(settings)
  const data = useStaticQuery(graphql`
    query {
      wordPress {
        mediaItems(first: 10000) {
          nodes {
            databaseId
            altText
            mediaItemUrl
          }
        }
      }
    }
  `)
  const mediaItems = data.wordPress.mediaItems.nodes.map(node => ({
    databaseId: node.databaseId,
    altText: node.altText,
    mediaItemUrl: node.mediaItemUrl,
  }))
  const { altText } = mediaItems.find(v => v.databaseId === settings.image.id)
  return (
    <div key={id} className="image-text-and-cta">
      <Img fluid={settings.image.fluid} alt={altText ? altText : null} />
      <div
        className="text"
        dangerouslySetInnerHTML={{ __html: settings.cta_description }}
      />
      <div className="button-primary cta">
        <a href={settings.cta_link.url}>{settings.cta_text}</a>
      </div>
    </div>
  )
}

export default ImageTextCTA
