import React from "react"
import ElementorRenderer from "./elementorrenderer"

const Footer = ({ footerData }) => {
  //console.log("footerData.elementorData=", footerData.elementorData)
  return (
    <footer>
      {footerData ? ElementorRenderer(footerData.elementorData) : ""}
    </footer>
  )
}

export default Footer
