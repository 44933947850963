import React from "react"
import Img from "gatsby-image"
import Popup from "reactjs-popup"
import { useStaticQuery, graphql } from "gatsby"
//import { useEffect } from "react"

const ImageGallery = ({ settings, id }) => {
  const data = useStaticQuery(graphql`
    query {
      wordPress {
        mediaItems(first: 10000) {
          nodes {
            databaseId
            altText
            mediaItemUrl
          }
        }
      }
    }
  `)
  const mediaItems = data.wordPress.mediaItems.nodes.map(node => ({
    databaseId: node.databaseId,
    altText: node.altText,
    mediaItemUrl: node.mediaItemUrl,
  }))
  settings.gallery.map(
    item =>
      (item.altText = mediaItems.find(v => v.databaseId === item.id).altText)
  )
  //console.log("ImageGallery settings=", settings)
  return (
    <div key={id} className="columns is-multiline">
      {settings.gallery.map((item, idx) => (
        <div key={idx} className="column is-one-quarter">
          <Popup
            trigger={
              <button>
                <Img
                  fluid={item.fluid}
                  alt={item.altText ? item.altText : null}
                />
              </button>
            }
            modal
            closeOnDocumentClick
            contentStyle={{
              width: "auto",
              background: "transparent",
              border: "0",
            }}
          >
            {close => (
              <React.Fragment>
                <div className="modal-content">
                  <Img fluid={item.fluid} />
                </div>
                <button
                  className="modal-close is-large"
                  aria-label="close"
                  onClick={close}
                ></button>
              </React.Fragment>
            )}
          </Popup>
        </div>
      ))}
    </div>
  )
}
export default ImageGallery
