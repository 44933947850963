import React from "react"

const CTA = ({ settings, id }) => {
  //console.log(settings)
  return (
    <div key={id} className="button-primary cta">
      <a href={settings.cta_link.url}>{settings.cta_text}</a>
    </div>
  )
}

export default CTA
