import React from "react"
import ElementorRenderer from "../elementorrenderer"

const Column = ({ id, settings: { _column_size }, elements }) => {
  //console.log("elt-column=", _column_size)
  return (
    <div key={id} className={"column-" + _column_size}>
      {ElementorRenderer(elements)}
    </div>
  )
}

export default Column
