import React from "react"

const textCTA = ({ settings, id }) => {
  //console.log(settings)
  return (
    <div key={id} className={"text-and-cta " + settings.style}>
      <div
        className="text"
        dangerouslySetInnerHTML={{ __html: settings.cta_description }}
      />
      <div className="button-primary cta">
        <a href={settings.cta_link.url}>{settings.cta_text}</a>
      </div>
    </div>
  )
}

export default textCTA
