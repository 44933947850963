import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
//import { useEffect } from "react"

const Image = ({ settings, id }) => {
  //console.log("settings image=", settings)
  const data = useStaticQuery(graphql`
    query {
      wordPress {
        mediaItems(first: 10000) {
          nodes {
            databaseId
            altText
            mediaItemUrl
          }
        }
      }
    }
  `)
  const mediaItems = data.wordPress.mediaItems.nodes.map(node => ({
    databaseId: node.databaseId,
    altText: node.altText,
    mediaItemUrl: node.mediaItemUrl,
  }))
  const { altText } = mediaItems.find(v => v.databaseId === settings.image.id)
  console.log("alt=", altText)
  return settings.has_link ? (
    <a key={id} href={settings.link.url}>
      <Img
        className={settings.style ? settings.style : null}
        fluid={settings.image.fluid}
        alt={altText ? altText : null}
      />
    </a>
  ) : (
    <Img
      key={id}
      className={settings.style ? settings.style : null}
      fluid={settings.image.fluid}
      alt={altText ? altText : null}
    />
  )
}
export default Image
