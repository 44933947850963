/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Header from "./header"
import Footer from "./footer"
import CookieConsent from "react-cookie-consent"
import { Link } from "gatsby"
import "./layout.css"
import Contact from "./widgets/Contact"

const Layout = ({ children, headerData, footerData, mainClass }) => {
  return (
    <React.Fragment>
      <CookieConsent
        location="bottom"
        buttonText="Accepter"
        buttonClasses="button is-success"
        declineButtonText="Refuser"
        declineButtonClasses="button"
        cookieName="gatsby-gdpr-google-analytics"
        enableDeclineButton
        style={{
          background: "#182d47f2",
          color: "#fff",
          padding: "0 1rem",
          boxShadow: "#182d47f2 0px 0px 20px 0px",
          alignItems: "center",
        }}
        buttonStyle={{
          background: "#399f70",
          color: "#fff",
        }}
      >
        En poursuivant votre navigation, vous acceptez le dépôt de cookies à des
        fins de statistiques, de personnalisation, et de partage sur les réseaux
        sociaux. Pour plus de renseignement consulter notre{" "}
        <Link to="/politique-cookies">Politique Cookies</Link>
      </CookieConsent>
      <div className={"page-" + mainClass}>
        <Header headerData={headerData} />
        <main>
          <Contact />
          {children}
        </main>
        <Footer footerData={footerData} />
      </div>
    </React.Fragment>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
