import React, { useState } from "react"
import gql from "graphql-tag"
import { Mutation } from "react-apollo"

const CONTACT_MUTATION = gql`
  mutation CreateSubmissionMutation(
    $clientMutationId: String!
    $email: String!
  ) {
    createSubmission(
      input: { clientMutationId: $clientMutationId, email: $email }
    ) {
      success
      data
    }
  }
`
const Newsletter = ({ settings, id }) => {
  //console.log("settings newsletter=", settings)
  const [emailValue, setEmailValue] = useState("")
  return (
    <Mutation key={id} mutation={CONTACT_MUTATION}>
      {(createSubmission, { loading, error, data }) => (
        <React.Fragment>
          <form
            className="newsletter"
            onSubmit={async event => {
              event.preventDefault()
              createSubmission({
                variables: {
                  clientMutationId: emailValue,
                  email: emailValue,
                },
              })
            }}
          >
            <h2 dangerouslySetInnerHTML={{ __html: settings.title }} />
            <div
              className="richtext"
              dangerouslySetInnerHTML={{ __html: settings.subtitle }}
            />
            <div className="field">
              <div className="control has-icons-left">
                <input
                  className="input"
                  type="email"
                  placeholder="Entrez votre email"
                  id="emailInput"
                  value={emailValue}
                  onChange={event => {
                    setEmailValue(event.target.value)
                  }}
                />
                <span className="icon is-small is-left">
                  <i className="fas fa-envelope"></i>
                </span>
                {loading && <p className="help">Chargement...</p>}
                {error && (
                  <p className="help is-danger">
                    Une erreur est survenue, veuillez réessayer
                  </p>
                )}
                {data && (
                  <p className="help is-success">
                    Merci pour votre inscription
                  </p>
                )}
              </div>
            </div>
            <div className="field">
              <div className="control">
                <label className="checkbox">
                  <input type="checkbox" required />
                  {settings.acceptance}
                </label>
              </div>
            </div>
            <div className="field">
              <div className="control has-text-centered">
                <button type="submit" className="button is-primary">
                  {settings.cta_text}
                </button>
              </div>
            </div>
          </form>
        </React.Fragment>
      )}
    </Mutation>
  )
}

export default Newsletter
