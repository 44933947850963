import React from "react"
import Img from "gatsby-image"
import "react-responsive-carousel/lib/styles/carousel.min.css" // requires a loader
import { Carousel } from "react-responsive-carousel"
import { useStaticQuery, graphql } from "gatsby"

const CarouselItems = ({ settings, id }) => {
  //console.log("settings carousel=", settings)
  const data = useStaticQuery(graphql`
    query {
      wordPress {
        mediaItems(first: 10000) {
          nodes {
            databaseId
            altText
            mediaItemUrl
          }
        }
      }
    }
  `)

  const mediaItems = data.wordPress.mediaItems.nodes.map(node => ({
    databaseId: node.databaseId,
    altText: node.altText,
    mediaItemUrl: node.mediaItemUrl,
  }))
  settings.carousel_items.map(
    item =>
      (item.carousel.altText = mediaItems.find(v => v.databaseId === item.carousel.id).altText)
  )
  // Initialize all elements with carousel class.
  return (
    <div key={id} className="carousel">
      <Carousel
        showStatus={false}
        showThumbs={false}
        infiniteLoop={true}
        autoPlay={true}
      >
        {settings.carousel_items.map((item, idx) => (
          <div key={idx}>
            <Img fluid={item.carousel.fluid} alt={item.carousel.altText ? item.carousel.altText : null} />
            <div className="link">
              <div dangerouslySetInnerHTML={{ __html:item.text}}/>
              <a href={item.link.url}>En savoir plus</a>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  )
}
export default CarouselItems
