import React from "react"

const listing = ({ settings, id }) => {
  //console.log(settings)
  return (
    <ul className="listing" key={id}>
      {React.createElement(settings.heading_size || "h2", {
        className: "listing-heading",
        dangerouslySetInnerHTML: { __html: settings.heading },
      })}
      {settings.list.map((item, idx) => (
        <li key={idx}>{item.list_entry}</li>
      ))}
    </ul>
  )
}

export default listing
